import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

const lambdaURL = process.env.REACT_APP_LAMBDA!

function LoginToken() {
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const code = query.get("code") as string
    const queryState = query.get("state")
    const [errorMsg, setErrorMsg] = useState("")
    useEffect(() => {
        if (queryState === "" || queryState !== localStorage.getItem("state")) {
            console.error("TODO: OAuth2 state does not match")
            return
        }
        const form = new FormData()
        form.set("code", code)
        let cancelled = false
        fetch(lambdaURL, {
            method: "POST",
            body: form,
        }).then((r) => {
            if (cancelled) {
                return
            }
            r.json().then((data) => {
                console.log(data)
                if (!!data.error) {
                    setErrorMsg(`${data.error} - ${data.error_description}`)
                }
            })
        }).catch(e => {
            if (cancelled) {
                return
            }
            setErrorMsg(`error enrolling Okta account: ${e}`)
        })
        return () => {
            cancelled = true
        }
    }, [code, queryState])
    if (errorMsg !== "") {
        return (
            <div id="login" className="container centered">
                <p className="error-message mt-6">{errorMsg}</p>
            </div>
        )
    }
    return (
        <div id="login" className="container centered">
            <div className="lds-hourglass"></div>
            <p>Wiring up the <a href="https://developer.okta.com/docs/reference/api/system-log/">Okta System Log API</a>...</p>
        </div>
    )
}

export default LoginToken
