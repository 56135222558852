import React, { useCallback, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Index from './pages/Index';
import LoginToken from './pages/LoginToken';

function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Index loggedIn={loggedIn} setLoggedIn={
            useCallback(
              (value: boolean) => setLoggedIn(value),
              [setLoggedIn],
            )
          } />
        </Route>
        <Route path="/login/token" exact>
          <LoginToken />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
