import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';


interface IndexProps {
    loggedIn: boolean
    setLoggedIn: (v: boolean) => void
}

function Index({ loggedIn, setLoggedIn }: IndexProps) {
    const [state, setState] = useState(localStorage.getItem("state") || "")
    if (state === "") {
        const newState = uuidv4()
        localStorage.setItem("state", newState)
        setState(newState)
    }
    // construct query string
    const searchParams = new URLSearchParams([
        ["client_id", process.env.REACT_APP_CLIENT_ID!],
        ["response_type", "code"],
        ["scope", "openid"],
        ["state", state],
        ["redirect_uri", process.env.REACT_APP_REDIRECT_URI!],
    ] as string[][]).toString()
    const loginURI = `https://${process.env.REACT_APP_OKTA_DOMAIN!}/oauth2/v1/authorize?${searchParams}`
    return (
        <div id="index">
            <header>
                <div className="container">
                    <div className="row centered">
                        <h2 className="mt-6">Link ThreatKey logging to Okta</h2>
                    </div>
                </div>
            </header>
            <div className="container centered">
                <a href={loginURI} className="button button-primary">Log in with Okta</a>
            </div>
        </div>
    )
}

export default Index
